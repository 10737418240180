<template>
  <div>
    <!-- BANNER -->
    <div id="carouselBanners" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="card imgBannerCardHeader mb-2">
            <div class="container">
              <div class="row">
                <div
                  :class="{
                    'col-6': windowWidth >= 991,
                    'col-12': windowWidth < 991,
                  }"
                >
                  <div class="mt-5">
                    <div class="titleCurso row">
                      <h1>
                        Venha aprender a programar para <span>Fivem</span>
                      </h1>
                      <p>
                        Aprenda como programar scripts de GTA RP <br />
                        e torne-se um programador profissional no mercado
                      </p>
                      <p>
                        Já são mais de <span>2500 alunos</span>, atualmente com
                        700 alunos ativos
                      </p>
                    </div>
                    <div class="row">
                      <a href="javascript;" class="comeceAgora mr-2"
                        >Comece Agora <i class="fal fa-angle-right"></i
                      ></a>
                      <a
                        href="https://discord.gg/eJQRPqVdKe"
                        target="_blank"
                        class="comeceAgora"
                        >Acesse Nosso Discord <i class="fal fa-angle-right"></i
                      ></a>
                    </div>
                  </div>
                </div>
                <div
                  :class="{
                    'col-6': windowWidth >= 991,
                    'display: none': windowWidth < 991,
                  }"
                >
                  <div class="align-center mb-2 mt-2">
                    <img
                      v-if="windowWidth >= 991"
                      src="https://media.discordapp.net/attachments/928755706235064350/1210640137323806781/fac.png?ex=65eb4b7d&is=65d8d67d&hm=f6b0a9f21fb7fbe448ea02d21302fba1f63c9ae5cb978c79754c779f0de17b9d&=&format=webp&quality=lossless&width=435&height=559"
                      alt="fac image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="card imgBannerCardHeader mb-2">
            <div class="container">
              <div class="row">
                <div class="col-6">
                  <div class="mt-5">
                    <div class="titleCurso row">
                      <h1>Scripts com performance e <span>Qualidade</span></h1>
                      <p>
                        Com nossos cursos você irá desenvolver scripts de GTA RP
                        para toda a comunidade de Fivem.
                      </p>
                      <p>
                        Aprenda a desenvolver scripts para FiveM com os melhores
                        frameworks vRPex e Creative atualidade.
                      </p>
                    </div>
                    <div class="row">
                      <a class="comeceAgora mr-2"
                        >Comece Agora <i class="fal fa-angle-right"></i
                      ></a>
                      <a
                        href="https://discord.gg/eJQRPqVdKe"
                        target="_blank"
                        class="comeceAgora"
                        >Acesse Nosso Discord <i class="fal fa-angle-right"></i
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="align-center mb-2 mt-2">
                    <img
                      src="https://media.discordapp.net/attachments/928755706235064350/1210640121360162846/asd.png?ex=65eb4b79&is=65d8d679&hm=cccb2f7e076c5998282788a5fc59257aac9339db080b14ff8ea6fa2d088773a4&=&format=webp&quality=lossless&width=428&height=559"
                      alt="Start image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselBanners"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselBanners"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>

    <!-- COMO FUNCIONA -->
    <div class="service-area card imgBannerCardContent pt-5 pb-5 mb-2">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-6 align-self-center">
            <div class="info-content">
              <p>
                Ao adquirir o curso, você nosso aluno recebe o acesso a nossa
                plataforma exclusiva EAD, seguido do servidor de Discord com a
                tag aluno, para ter acesso a demais materias de download, Grupo
                WhatsApp/Telegram e suporte.
              </p>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="heading">
              <strong class="filltext">Cursos</strong>
              <small>Nosso curso</small>
              <h2>Como funciona o <span>curso?</span></h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="single-service">
              <div class="service-icon">
                <img
                  src="@/assets/admin\images\svgs\service2.svg"
                  alt="Plataforma EAD"
                />
              </div>
              <div class="service-text">
                <h4>Plataforma EAD</h4>
                <p>
                  Ao realizar a compra do curso você tem acesso imediato a todas
                  as aulas e os materias de apoio.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-service active">
              <div class="service-icon">
                <img src="@/assets/admin\images\svgs\service1.svg" alt="" />
              </div>
              <div class="service-text">
                <h4>Suporte Discord</h4>
                <p>
                  Você terá total suporte via discord para tirar suas dúvidas de
                  assuntos relacionados ao curso.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-service">
              <div class="service-icon">
                <img src="@/assets/admin\images\svgs\service3.svg" alt="" />
              </div>
              <div class="service-text">
                <h4>Aulas do curso</h4>
                <p>
                  Videoaulas com +300 aulas com apostila, código de apoio,
                  brindes e uma equipe para suporte.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- GRADE -->
    <div class="service-area card imgBannerCardContent pt-5 pb-5 mb-2">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-6 align-self-center">
            <div class="heading">
              <strong class="filltext">Grade</strong>
              <small>Grade Curricular</small>
              <h2>O que voce irá <span>aprender</span></h2>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="info-content">
              <p>
                Aprenda a desenvolver scripts de FiveM para GTA, do zero ao
                avançado, com foco nos principais Frameworks, vRPex e Creative
                com as principais linguagens da atualidade.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="carouselTeste" class="carousel" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="container">
              <div class="row">
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/fivem.png"
                        alt="Fivem"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Fivem</h4>
                      <ol>
                        <li>Personalização avançada</li>
                        <li>Liberdade e criatividade</li>
                        <li>Fundamentos de RP</li>
                        <li>Criação de bases</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/fivem-cfg.png"
                        alt="Gerenciamento Fivem"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Gerenciamento Fivem</h4>
                      <ol>
                        <li>Multiplayer personalizado</li>
                        <li>Estabilidade e desempenho</li>
                        <li>Comunidade ativa e envolvida</li>
                        <li>Diversidade de servidores</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/podcast.png"
                        alt="Podcast"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Podcasts</h4>
                      <ol>
                        <li>Conexão Direta com a Audiência</li>
                        <li>Baixo Custo de Produção e Acesso</li>
                        <li>Diversidade de Conteúdo</li>
                        <li>Acessibilidade e Flexibilidade</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="container">
              <div class="row">
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/html-css.png"
                        alt="HTML & CSS"
                      />
                    </div>
                    <div class="service-text">
                      <h4>HTML & CSS</h4>
                      <ol>
                        <li>Compatibilidade</li>
                        <li>Estrutura básica da web</li>
                        <li>Estilização de páginas da web</li>
                        <li>Reutilização de estilos</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/javascript.png"
                        alt="Javascript"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Javascript</h4>
                      <ol>
                        <li>Interatividade e dinamismo</li>
                        <li>Ampla adoção e suporte</li>
                        <li>Versatilidade</li>
                        <li>Integração de tecnologias</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/mysql.png"
                        alt="Mysql"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Mysql</h4>
                      <ol>
                        <li>Manipulação de dados</li>
                        <li>gerenciamento de dados</li>
                        <li>Documentação abrangente</li>
                        <li>Suporte a consultas complexas</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="container">
              <div class="row">
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/vue.png"
                        alt="VueJs"
                      />
                    </div>
                    <div class="service-text">
                      <h4>VueJs</h4>
                      <ol>
                        <li>Simplicidade e facilidade de uso</li>
                        <li>Ecossistema e comunidade ativa</li>
                        <li>Eficiência de desenvolvimento</li>
                        <li>Reatividade</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/jquery.png"
                        alt="Jquery"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Jquery</h4>
                      <ol>
                        <li>Manipulação simplificada do DOM</li>
                        <li>Acelerador de desenvolvimento</li>
                        <li>Compatibilidade e suporte</li>
                        <li>Biblioteca extensível</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/bootstrap.png"
                        alt="Bootstrap"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Bootstrap</h4>
                      <ol>
                        <li>Responsividade</li>
                        <li>Grid System flexível</li>
                        <li>Documentação detalhada</li>
                        <li>Componentes pré-estilizados</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="container">
              <div class="row">
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/lua.png"
                        alt="Lua"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Lua</h4>
                      <ol>
                        <li>Simplicidade e facilidade de uso</li>
                        <li>Alta performance</li>
                        <li>Integração e extensibilidade</li>
                        <li>Flexibilidade e controle</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/node.png"
                        alt="Node"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Node</h4>
                      <ol>
                        <li>JavaScript em Ambos os Lados</li>
                        <li>Modelo de E/S Não-Bloqueante</li>
                        <li>Ecossistema NPM</li>
                        <li>Alta Performance</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="single-service">
                    <div class="service-icon">
                      <img
                        class="service-img"
                        src="@/assets/admin/images/fivem/python.png"
                        alt="Python"
                      />
                    </div>
                    <div class="service-text">
                      <h4>Python</h4>
                      <ol>
                        <li>Sintaxe Clara e Legibilidade</li>
                        <li>Ampla Biblioteca Padrão</li>
                        <li>Versatilidade e Facilidade</li>
                        <li>Comunidade Ativa e Suporte</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselTeste"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselTeste"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>

    <!-- TEACHER -->
    <div class="card imgBannerCardContent pt-5 mb-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="mr-4">
              <div class="single-blog-section">
                <div class="single-blog-section-description blog-box">
                  <h2>Scripts desenvolvidos pelo professor JAVA</h2>
                  <div class="single-blog-section-author">
                    <ul>
                      <li>
                        <a class="blog-author" href="javascript:void(0)"
                          ><img
                            src="@/assets/admin/images/teacher/icone.png"
                            alt="logo Édipo"
                          />Édipo Oliveira Sapanhos</a
                        >
                      </li>
                      <li>
                        <a href="https://discord.gg/eJQRPqVdKe"
                          ><i class="fal fa-comments-alt"></i> Tire dúvidas</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-12 d-flex mb-2 video-iframe-col6">
                    <div class="col-lg-6 col-sm-6">
                      <h6 class="text-center white">Monkey System v2</h6>
                      <iframe
                        style="width: 100%"
                        src="https://www.youtube.com/embed/nd05ADxsXSs"
                        frameborder="0"
                      ></iframe>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                      <h6 class="text-center white">Monkey Inventário</h6>
                      <iframe
                        style="width: 100%"
                        src="https://www.youtube.com/embed/-OMHQmv3sw8"
                        frameborder="0"
                      ></iframe>
                    </div>
                  </div>
                  <div class="col-lg-12 video-iframe-col12">
                    <h6 class="text-center white">Monkey Doors</h6>
                    <iframe
                      style="width: 100%; height: 350px"
                      src="https://www.youtube.com/embed/01c_0aaUV9o"
                      frameborder="0"
                    ></iframe>
                  </div>
                  <a
                    href="https://www.youtube.com/@MonkeyCanal/featured"
                    target="_blank"
                  >
                    <p class="text-center">
                      <i class="fab fa-youtube text-danger"></i> Link do Canal
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="blog-page-right text-center">
              <div class="blog-box blog-author">
                <div class="blog-author-image">
                  <img
                    src="@/assets/admin/images/teacher/edipo_oliviera.png"
                    alt="Édipo Oliveira Sapanhos"
                  />
                  <div class="border-effect"></div>
                  <h6>Professor Édipo Oliveira Sapanhos (JAVA)</h6>
                </div>
                <div class="blog-author-description">
                  <p class="text-left">
                    Bacharel em Sistema da Informação, Pós Graduado em
                    Desenvolvimento de Sistema Web Tecnólogo em Redes, Hardware
                    e Web Design Programador, Empresário e Palestrante Diretor
                    da One Way e da Redes de Super Mercados Paulista Trabalhou
                    no desenvolvimento de sistemas para TRC taborda, depois via
                    BRQ para Sanepar, Sicob, Bradesco, Porto Seguro. Fundou a
                    OneWay, atualmente atua no desenvolvimento de ERPs para
                    super mercados, scripts para servidores de FiveM, APIs para
                    sistemas de cobrança sitef e sistemas de integração com
                    receita federal, programando mais de 40 linguagens
                    Programador Sênior Full Stack.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- GANHE COM ISSO -->
    <div class="service-area card imgBannerCardContent pt-5 pb-5 mb-2">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-6 align-self-center">
            <div class="info-content">
              <p>
                Você já imaginou ter uma oportunidade incrível de ganhar
                dinheiro e conquistar a independência financeira?
              </p>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="heading">
              <strong class="filltext">Dinheiro</strong>
              <h2>Como ganhar <span>dinheiro</span> com isso</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="single-service">
              <div class="service-icon">
                <img
                  src="@/assets/admin/images/svgs/invoice-receipt-svgrepo-com.svg"
                  alt="Desenvolvimento"
                />
              </div>
              <div class="service-text">
                <h4>Desenvolvimento</h4>
                <p>
                  Monetize seus códigos e comece a ganhar dinheiro com seus
                  próprios scripts. Cada vez mais clientes de FiveM solicitam
                  novos scripts exclusivos para suas cidades, não perca tempo e
                  de um ponta pé inicial, comece a vender para faturar com seus
                  próprios scripts.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-service active">
              <div class="service-icon">
                <img
                  src="@/assets/admin/images/svgs/money-cash-svgrepo-com.svg"
                  alt="Consultoria"
                />
              </div>
              <div class="service-text">
                <h4>Consultoria</h4>
                <p>
                  Suporte a cidades são cada vez mais necessitados, cada vez
                  mais programdores são requisitados para correções de bugs,
                  ajustes, modificações e muito mais. Comece agora a realizar
                  manutenções e suporte para cidades de GTA RP.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-service">
              <div class="service-icon">
                <img
                  src="@/assets/admin/images/svgs/hourglass-svgrepo-com.svg"
                  alt="Abra sua cidade"
                />
              </div>
              <div class="service-text">
                <h4>Abra sua cidade</h4>
                <p>
                  Você já sonhou em criar e administrar sua própria cidade? Você
                  pode transformar esse sonho em realidade! Você tem a
                  oportunidade única de abrir uma cidade no mundo RP com
                  interação ilimitadas, que iram lhe gerar resultados.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- NÃO PERCA TEMPO -->
    <div class="service-area card imgBannerCardContent pt-5 pb-5 mb-2">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-6 align-self-center">
            <div class="heading">
              <strong class="filltext">Confira agora</strong>
              <small>Comece Agora</small>
              <h2>Não perca <span>tempo</span> garanta já o seu acesso!</h2>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="info-content">
              <p>
                <b>Aproveite está oferta e adquira agora mesmo.</b> Com este
                curso revolucionário você vai transformar a maneira como você
                encara os desafios profissionais
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 video-iframe-col12">
            <iframe
              width="100%"
              height="560"
              src="https://www.youtube.com/embed/tw2Z9yeU-5w?si=YC_kGxWhMkq30pLn"
              title="Video Trabalhe Com GTA"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- PACOTE -->
    <div class="service-area card imgBannerCardContent pt-5 pb-5 mb-2">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-6 align-self-center">
              <h4><b>Vagas limitadas</b> <br> de <span class="color-theme texto-cortado">R$ 4370,00</span> por <span class="color-theme">R$ 1650,00</span> em até 12x</h4>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="heading">
              <strong class="filltext">Compre Agora</strong>
              <small>Confira Agora já com a melhor, a Top 1 Brasil - Monkey</small
              >
              <h2>
                Pacote 3 <span>FULLSTACK</span> <br />
                + Base Creative <span>Monkey</span>
              </h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="info-content mb-5">
              <p><b>Base auto gerenciável</b> <br> confira o vídeo da base:</p>
              <a class="ver-video" href="https://www.youtube.com/watch?v=nNAN605EDXY" target="_blank"> <i class="fab fa-youtube color-theme mr-1"></i> Ver vídeo</a>
            </div>
            <div class="info-content mb-5">
              <p><b>c/ Suporte</b> <br> de segunda a sexta via ticket e call.</p>
            </div>
            <div class="info-content mb-5">
              <p>
                <b>c/ instalação</b> <br> fazemos a instalação e deixamos 100%
                funcional para você começar seu sonho de ter uma cidade.
              </p>
            </div>
            <div class="info-content mb-5">
              <p>
                <b>Top 1 Brasil</b> <br> ZERO Bugs, Base Monkey totalmente auto
                genrenciável, ela vem com nossos principais módulos.
              </p>
            </div>
            <div class="info-content">
              <p>
                <b>Para saber mais dessa base acesse:</b> <br>
                <a href="http://vrpex.com.br/documentacao" target="_blank" class="btn btn-outline-primary rounded mt-2"><i class="fas fa-book mr-2"></i> Documentação da Base</a>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class="align-center mb-2 mt-2">
              <img
                class="creative-img"
                src="@/assets/admin/images/fivem/logo creative.png"
                alt="Start image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "NossosCursos",
  data: function () {
    return {
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
};
</script>

<style scoped>
.ver-video:hover{
  color: #dd0f20;
}
.service-img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.creative-img{
  width: 100%;
}

.imgBannerCardHeader {
  background-size: auto;
  background-image: url("https://media.discordapp.net/attachments/928755706235064350/1210643782329180301/wlc-bg1.jpg?ex=65eb4ee2&is=65d8d9e2&hm=2ec4591b22fc521e07b0d294fba9f1912614b6360e161847638ed5608ba5dad5&=&format=webp&width=1004&height=558");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.imgBannerCardContent {
  background-size: auto;
  background-image: url("https://media.discordapp.net/attachments/928755706235064350/1210643780668104815/Header.png?ex=65eb4ee1&is=65d8d9e1&hm=cc31637464653ca8b00cdf27159fcaf9fcb37e5d6652476f11da4294fb279fec&=&format=webp&quality=lossless&width=938&height=559");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.card {
  margin-bottom: 0;
}

.single-service {
  text-align: center;
  padding: 50px 40px;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: #131617;
}

.service-text h4 {
  font-size: 24px;
  text-transform: uppercase;
  color: #dd0f20;
  margin-bottom: 30px;
  font-weight: bold;
}

.service-text {
  margin: 30px 0 30px 0px;
}

.service-area .service-text {
  margin: 30px 0 0px 0px;
}

.service-text p {
  color: #999999;

  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.single-service.active,
.single-service:hover {
  background: #343a40;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.titleCurso h1 {
  text-transform: uppercase;
  font-size: 50px;
  line-height: 70px;
  margin-bottom: 20px;
  font-weight: bold;
}

.titleCurso h1 span {
  color: #dd0f20;
}

.titleCurso p {
  font-weight: 400;
  font-size: 18px;
  padding-left: 20px;
  border-left: 3px solid #dd0f20;
  line-height: 28px;
  margin: 20px 0;
}

.texto-cortado {
  color: #8d8d8d;
  text-decoration: line-through;
  text-decoration-color: var(--primary-color);
  text-decoration-thickness: 2px;
}

.buttonComecar {
  --color: #dd0f20;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: transparent;
  border-radius: 0.3em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 600;
  font-size: 18px;
  border: 1px solid;
  font-family: inherit;
  text-transform: uppercase;
  color: var(--color);
  z-index: 1;
}

.buttonComecar::before,
.buttonComecar::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 0.5s ease;
}

.buttonComecar::before {
  top: -1em;
  left: -1em;
}

.buttonComecar::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.buttonComecar:hover::before,
.buttonComecar:hover::after {
  height: 410px;
  width: 410px;
}

.buttonComecar:hover {
  color: rgb(255, 255, 255);
}

.buttonComecar:active {
  filter: brightness(0.8);
}

/* Button */

a.comeceAgora {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  padding: 13px 25px;
  background: red;
  border-radius: 4px;
}

a.comeceAgora {
  background: #dd0f20;
  padding-right: 70px;
  position: relative;
  z-index: 2;
  border: 1px solid transparent;
  color: #fff;
}

a.comeceAgora i {
  position: absolute;
  top: 50%;
  right: 15px;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  background: #fff;
  color: #dd0f20;
  border-radius: 4px;
  margin-top: -15px;
}

a.comeceAgora:hover {
  border-color: #b60d1b;
  background: transparent;
  color: #b60d1b;
  transition: all 0.3s;
}

.heading h2 {
  font-size: 44px;
  line-height: 55px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.heading h3 {
  color: #ffffff;
  opacity: 0.2;
  font-size: 35px;
  border: none;
  background: transparent;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.heading h3::before {
  margin-left: auto;
}

.heading h3::after,
.heading h3::before {
  content: "";
  width: 0%;
  height: 2px;
  background: #f44336;
  display: block;
  transition: 0.5s;
}

.heading h3:hover::after,
.heading h3:hover::before {
  width: 100%;
}

.heading h2 span {
  color: #b60d1b;
}

.heading {
  position: relative;
  z-index: 2;
}

.heading strong.filltext {
  font-size: 80px;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #171c1e;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.heading p {
  font-weight: 400;
  margin: 40px 0;
}

.primery-heading small {
  font-weight: 500;
  color: #dd0f20 !important;
  font-size: 16px;
  text-transform: uppercase;
}

.primery-heading h2 {
  font-size: 48px;
  line-height: 62px;
  text-transform: uppercase;
  color: #0b0d0e;
  font-weight: 600;
  margin-bottom: 0;
}

.primery-heading h2 span {
  color: #dd0f20;
}

.primery-heading {
  position: relative;
  z-index: 2;
}

.primery-heading strong.filltext {
  font-size: 80px;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  -webkit-text-fill-color: #f1f1f1;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.primery-heading p {
  font-weight: 400;
  font-size: 17px;
  color: #0b0d0e;
}

.heading small {
  font-weight: 500;
  color: #dd0f20 !important;
  font-size: 16px;
  text-transform: uppercase;
}

.primery-info-content {
  padding-left: 30px;
  border-left: 3px solid #dd0f20;
  color: #fff;
}

.primery-info-content p {
  font-size: 18px;

  font-weight: 400;
  color: #dd0f20;
  margin-bottom: 0;
}

.service-icon {
  /* width: 90px; */
  margin: 0 auto;
}

.info-content {
  padding-left: 30px;
  border-left: 3px solid #dd0f20;
  color: #fff;
}

.info-content p {
  font-size: 18px;

  font-weight: 400;
  margin-bottom: 0;
}

/* teacher */

.single-blog-section-description h2,
.single-blog-section-description h2 a {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 40px;
  color: #ffffff;
  margin: 0;
  letter-spacing: 0;
}

.single-blog-section-description h2:hover,
.single-blog-section-description h2 a:hover {
  color: #dd0f20;
}

.single-blog-section-description {
  padding: 30px 0 50px 0;
}
.single-blog-section-description p {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.single-blog-section-author li {
  display: inline-block;
  padding: 25px 0;
  padding-right: 15px;
}

.single-blog-section-author li a {
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  color: #ffffff;
}

a.blog-author {
  padding-left: 50px;
  position: relative;
  z-index: 2;
}

a.blog-author img {
  position: absolute;
  height: 40px;
  width: 40px;
  left: 0;
  top: 50%;
  margin-top: -20px;
  border-radius: 50%;
  border: 1px solid #dd0f20;
}

.single-blog-section-author li a i {
  color: #dd0f20;
  padding-right: 5px;
}

.blog-author-image h6 {
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.7;
  margin: 15px 0;
}
.single-blog-section:hover span {
  background: #dd0f20;
}

.blog-page-social li {
  display: inline;
  margin-left: 10px;
}
.blog-page-social li:first-child {
  margin-left: 0;
}
.blog-page-social li a {
  font-size: 18px;
}

.blog-banner {
  padding: 50px 60px;
  background: #ffffff;
  color: #fff;
}

.blog-banner h3 {
  text-transform: uppercase;
  font-size: 30px;
  color: #fff;
  line-height: 40px;
}

.blog-box h3 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 40px;
}

.blog-box span {
  color: #dd0f20;
}

.blog-author-image img {
  width: 100%;
}

.blog-author-image p {
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.7;
  margin: 20px 0;
}

.blog-author-description p {
  padding: 30px 0;
}

.blog-author-description video {
  width: 100%;
}

.blog-box p {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.7;
  margin: 0;
}
</style>

